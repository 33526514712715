<template>
  <div class="subPage">
    <div id="generalOrder">
      <general-order :orders="orders" @reLoad="getSearchDatas" userType="seller" gzFlag="G"></general-order>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import generalOrder from "../../../base/generalOrder/generalOrder";
export default {
  components: {
    "general-order": generalOrder
  },
  data() {
    return {
      current: 1,
      pageSize: 10,
      totalPage: null,
      tableDatas: [],
      searchForm: {
        orderId: null,
        startTime: null,
        endTime: null,
        orderTime: null,
        orderStatus: null,
        payStatus: null,
        openTicketStatus: null,
        pickResourcesStatus:null,
        pickResourcesStatus:null
      },
      orders: []
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  mounted() {
    let that = this
    setTimeout(function(){that.getDatas()},50)
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    getDatas(search) {
      protocolFwd.param_queryFirmOrder.param = {
        page: this.current - 1,
        size: this.pageSize,
        // sort: [{property: "orderId", direction: "DESC"}],
        hangFirmId: sessionStorage.getItem('userFirmId'),
        orderId: this.searchForm.orderId,
        startTime: this.searchForm.startTime,
        endTime: this.searchForm.endTime,
        tradeStatus: this.searchForm.orderStatus,
        payStatus: this.searchForm.payStatus,
        openTicketStatus: this.searchForm.openTicketStatus,
        pickResourcesStatus:this.searchForm.pickResourcesStatus,
        arrivalStatus: null,
        // pickType: this.searchForm.pickType,
        // pickStatus: this.searchForm.pickStatus,
        sourceId:'C',
        hangResourceType:['B']
      }
      http.postFront(protocolFwd.param_queryFirmOrder).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.orders = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getSearchDatas(search) {
      this.searchForm = search;
      this.getDatas();
    }
  }
};
</script>
<style scoped>
.subPage {
  padding: 0 15px;
}
.subPageTitle {
  overflow: hidden;
}
.subPageTitle .subPageTitleText {
  float: left;
  line-height: 40px;
}
.subPageTitle .subPageTitleText p {
  font-size: 16px;
}
.subPageTitle .addButton {
  float: right;
}
.searchList {
  margin: 10px 0;
}
.tableList {
  margin-top: 20px;
}
.subPage .pagination {
  margin-top: 15px;
}
</style>
